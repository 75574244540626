import React, { useState, useLayoutEffect } from "react"
import styled from "styled-components"
import { useSpring, animated } from "react-spring"
import { Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"

import Spacer from "./Spacer"
import InternalLink from "./InternalLink"
import { breakpoints } from "../assets/globalStyles"

const StyledCaseStudyWrapper = styled(Link)`
  position: relative;
  border: 1px solid #eef1f4;
  background-color: #ffffff;
  display: block;
  @media screen and (min-width: ${breakpoints.md}px) {
    padding-bottom: 110px;
  }
  .image-background {
    background-color: #eef1f4;
    padding: 24px;
    height: 250px;
    @media screen and (min-width: ${breakpoints.md}px) {
      max-height: 100%;
      height: ${({ tall }) => {
        if (tall) return "380px"
        return "250px"
      }};
    }
    > img {
      height: 100%;
      width: 100%;
      object-fit: contain;
    }
  }
  .case-study-details {
    background-color: #ffffff;
    padding: 24px;
    width: 100%;
    @media screen and (min-width: ${breakpoints.md}px) {
      position: absolute;
      overflow: hidden;
      bottom: 0;
    }
    h3 {
      color: #1c1c26;
      font-family: Poppins;
      font-size: 20px;
      line-height: 32px;
      overflow: hidden;
      text-overflow: ellipsis;
      margin-bottom: 0 !important;
    }
    .case-study-meta {
      color: #66768e;
      font-family: Roboto;
      font-size: 16px;
      line-height: 24px;
      overflow: hidden;
      text-overflow: ellipsis;
      @media screen and (min-width: ${breakpoints.md}px) {
        white-space: nowrap;
      }
    }
    .case-study-summary {
      display: none;
      @media screen and (min-width: ${breakpoints.md}px) {
        display: block;
        color: #1c1c26;
        font-family: Roboto;
        font-size: 18px;
        line-height: 28px;
      }
    }
    .view-more {
      display: none;
      @media screen and (min-width: ${breakpoints.md}px) {
        display: inline-flex;
      }
    }
  }
`

export default function CaseStudyBox({
  caseStudy: {
    featuredImage: { node: featuredImageDetails },
    title,
    categories: { nodes: categories },
    caseStudy: { clientIndustry, customerType, summary },
    slug,
  },
  tall,
}) {
  const [windowWidth, setWindowWidth] = useState(
    typeof window === "undefined" ? 0 : window.innerWidth
  )

  useLayoutEffect(() => {
    if (typeof window === "undefined") return

    const handleResize = () => setWindowWidth(window.innerWidth)
    window.addEventListener("resize", handleResize)
    return () => {
      window.removeEventListener("resize", handleResize)
    }
  })

  const [isHovered, setIsHovered] = useState(false)

  const titleAnimation = useSpring({
    whiteSpace:
      isHovered || windowWidth < breakpoints.md ? "break-spaces" : "nowrap",
  })

  const detailsAnimation = useSpring({
    maxHeight: isHovered || windowWidth < breakpoints.md ? "360px" : "112px",
  })

  // get all categories
  const category = categories.map(({ name }) => name).join(", ")

  // trim summary

  let snippedDescription = summary.substring(0, tall ? 400 : 100)

  if (snippedDescription.length < summary.length) {
    snippedDescription = snippedDescription.substr(
      0,
      Math.min(snippedDescription.length, snippedDescription.lastIndexOf(" "))
    )
    snippedDescription += "..."
  }

  // format featured image based on the file type

  const imageType = featuredImageDetails.mimeType
  const imageUrl = featuredImageDetails.localFile?.publicURL
  const gatsbyImageData =
    featuredImageDetails.localFile?.childImageSharp?.gatsbyImageData

  return (
    <StyledCaseStudyWrapper
      to={`/work/${slug}/`}
      tall={tall ? 1 : 0}
      onMouseEnter={() => {
        setIsHovered(true)
      }}
      onMouseLeave={() => {
        setIsHovered(false)
      }}
    >
      <div className="image-background">
        {imageType === "image/svg+xml" ? (
          <img src={imageUrl} alt={title} />
        ) : (
          <GatsbyImage
            image={gatsbyImageData}
            alt={title}
            objectFit="contain"
            style={{ maxHeight: "100%", height: "100%" }}
            onMouseEnter={() => {
              setIsHovered(true)
            }}
            onMouseLeave={() => {
              setIsHovered(false)
            }}
          />
        )}
      </div>
      <animated.div className="case-study-details" style={detailsAnimation}>
        <animated.h3 style={titleAnimation}>{title}</animated.h3>
        <Spacer size={8} />
        <animated.p className="case-study-meta">{`${category} | ${customerType} in ${clientIndustry}`}</animated.p>
        <Spacer size={0} when={{ md: 24 }} />
        <p className="case-study-summary"> {snippedDescription} </p>
        <Spacer size={0} when={{ md: 8 }} />
        <InternalLink as="div" className="view-more">
          View Project
        </InternalLink>
      </animated.div>
    </StyledCaseStudyWrapper>
  )
}
