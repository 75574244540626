import React from "react"
import { Link, useStaticQuery, graphql } from "gatsby"
import styled from "styled-components"

import Divider from "./Divider"
import PinkChevronIcon from "./../assets/icons/pink-chevron.svg"
import InternalLink from "./InternalLink"
import { breakpoints } from "../assets/globalStyles"
import Button from "./Button"
import Spacer from "./Spacer"
import BlogPostBox from "./BlogPostBox"

const StyledBlogPostsContainer = styled.div`
  .title {
    display: flex;
    justify-content: space-between;
    .desktop-view-more {
      display: none;
      @media screen and (min-width: ${breakpoints.md}px) {
        display: inline-flex;
      }
    }
  }

  .mobile-view-more {
    @media screen and (min-width: ${breakpoints.md}px) {
      display: none;
    }
  }
`

export default function BlogPreview({ relatedBlogPosts }) {
  const {
    allWpPost: { nodes: allBlogPosts },
  } = useStaticQuery(graphql`
    {
      allWpPost(sort: { fields: date, order: DESC }, limit: 2) {
        nodes {
          id
          title
          slug
          date(formatString: "DD/MM/YYYY")
          featuredImage {
            node {
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    layout: FULL_WIDTH
                    placeholder: NONE
                    quality: 90
                  )
                }
                publicURL
              }
              mimeType
            }
          }
          author {
            node {
              firstName
              lastName
            }
          }
          categories {
            nodes {
              name
            }
          }
        }
      }
    }
  `)

  let blogPosts = relatedBlogPosts?.length ? relatedBlogPosts : allBlogPosts

  // Format the data to make it easier to work with

  blogPosts = blogPosts.map(
    ({
      author: {
        node: { firstName, lastName },
      },
      date,
      id,
      slug,
      title,
      featuredImage: { node: imageData },
      categories: { nodes: categoryArray },
    }) => ({
      author: `${firstName} ${lastName}`,
      date,
      id,
      slug,
      title,
      featuredImage: {
        imageType: imageData.mimeType,
        imageUrl: imageData.localFile?.publicURL,
        gatsbyImageData: imageData.localFile?.childImageSharp?.gatsbyImageData,
      },
      categories: categoryArray.map(({ name }) => name),
    })
  )

  return (
    <>
      <Divider />
      <StyledBlogPostsContainer className="container">
        <div className="row">
          <div className="col-12">
            <Spacer size={32} when={{ lg: 48 }} />
            <div className="title">
              <h2>
                {relatedBlogPosts?.length ? "Related Posts" : "From our blog"}
              </h2>
              <InternalLink to="/blog/" className="desktop-view-more">
                View all posts
              </InternalLink>
            </div>
            <Spacer size={24} />
          </div>
        </div>
        <div className="row">
          {blogPosts.map(({ id, ...blogPostDetails }, index) => (
            <div
              className={`col-12 col-md-6 ${!index ? "col-lg-8" : "col-lg-4"}`}
              key={id}
            >
              <BlogPostBox blogPost={blogPostDetails} />
              <Spacer size={24} when={{ md: 0 }} />
            </div>
          ))}
        </div>
        <div className="row">
          <div className="col-12">
            <Button
              to="/blog/"
              as={Link}
              className="mobile-view-more"
              variant="outline"
              colour="primary"
              icon={
                <img
                  src={PinkChevronIcon}
                  alt="view all posts"
                  style={{ height: "16px", marginLeft: "8px" }}
                />
              }
            >
              View all posts
            </Button>
          </div>
        </div>
      </StyledBlogPostsContainer>
      <Spacer size={32} when={{ md: 48, lg: 80 }} />
    </>
  )
}
