import React, { useState } from "react"
import styled from "styled-components"
import { useSpring, animated } from "react-spring"
import { Link } from "gatsby"

import PinkChevronIcon from "./../assets/icons/pink-chevron.svg"

export const StyledInternalLink = styled(Link)`
  display: inline-flex;
  color: #1c1c26;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  align-items: center;
  justify-content: center;
  &:hover {
    color: #1c1c26;
  }
`

export default function InternalLink({ children, ...rest }) {
  const [isHovered, setIsHovered] = useState(false)

  const arrowAnimation = useSpring({
    transform: isHovered ? "translateX(5px)" : "translateX(0px)",
    config: {
      tension: 300,
      friction: 15,
    },
  })

  return (
    <StyledInternalLink
      {...rest}
      onMouseEnter={() => {
        setIsHovered(true)
      }}
      onMouseLeave={() => {
        setIsHovered(false)
      }}
    >
      {children}
      <animated.img
        src={PinkChevronIcon}
        alt=""
        style={{ ...arrowAnimation, height: "16px", marginLeft: "8px" }}
      />
    </StyledInternalLink>
  )
}
